import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Menu from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import Check from "../assets/checkmark1.svg";
import ToggleOn from '../assets/ToggleOn-01.svg';
import ToggleOff from '../assets/ToggleOff-01.svg';
import { FaPencilAlt, FaTrashAlt, FaFolder, FaCircleNotch } from "react-icons/fa";
import Loader from "../assets/loading.gif";
import ImageUploading from 'react-images-uploading';
import  secureLocalStorage  from  "react-secure-storage";
import GeneralPopup from './Modals/GeneralPopup';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import {  getFiles, uploadFile, deleteFile, updateClientBranding, updateQuickGenerate, updateTempEditRestriction, updateClientEstContact, updateClientWrkContact} from './../graphql/queries';
import config from '../aws-exports';
Amplify.configure(config);
export default function Settings(props) {
    const [role , setRole] = useState('Member');
    const [color, setColor] = useState('#da8227');
    const [logo, setLogo] = useState('//roofscope.com/app/view/theme/polyscope/image/logo.png#file_id=8');

    const [imageManger, setImageManger] = useState(false);
    const [loading, setLoading] = useState(false);
    const [folderId, setFolderId] = useState(0);
    const [showAU, setShowAU] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [images, setImages] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [showDeleteModal, setShowDeleteModal] =  useState(false);
    const [deleteImageInfo, setDeleteImageInfo] = useState({});
    const [deleteImageIndex, setDeleteImageIndex] = useState([]);

    const [oneClick, setOneClick] = useState(false);
    const [editRestiction, setEditRestriction] = useState(false);

    const [estEmail, setEstEmail] = useState('');
    const [estFirstName, setEstFirstName] = useState('');
    const [estLastName, setEstLastName] = useState('');
    const [estPhone, setEstPhone] = useState('');

    const [wrkEmail, setWrkEmail] = useState('');
    const [wrkFirstName, setWrkFirstName] = useState('');
    const [wrkLastName, setWrkLastName] = useState('');
    const [wrkPhone, setWrkPhone] = useState('');

    const [customerId, setCustomerId] = useState(0);

    const maxNumber = 100;

    useEffect(() => {
        let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
        let team_role = JSON.parse(secureLocalStorage.getItem("client_data")).team_role;
        let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;
        if (team_role == "Leader" || team_role == "Admin") {
        setRole(team_role);
            }
        if ((team_role == '' || team_role == null) && client_id == leader_id) {
        setRole('Leader');
            }
            console.log(role , "role");
        },[])
        
    async function setColorOptions(color){
        console.log('setColorOptions, color:', color);
        setColor(color);
        let data = JSON.parse(secureLocalStorage.getItem('client_data'));
        data.color = color;
        console.log('setColorOptions data>>>', data);
        secureLocalStorage.setItem('client_data', JSON.stringify(data));

        const updateColor = await API.graphql({
            query: updateClientBranding,
            variables: {
                client_id: customerId,
                color: color,
                logo: logo
            }
        });
        console.log('setColorOptions updateColor>>>', updateColor);
    }

    async function setLogoOptions(logo){
        console.log('setLogoOptions, logo:', logo);
        setLogo(logo);
        let data = JSON.parse(secureLocalStorage.getItem('client_data'));
        data.logo = logo;
        console.log('setLogoOptions data>>>', data);
        secureLocalStorage.setItem('client_data', JSON.stringify(data));
        
        const updateColor = await API.graphql({
            query: updateClientBranding,
            variables: {
                client_id: customerId,
                color: color,
                logo: logo
            }
        });
        console.log('setLogoOptions updateColor>>>', updateColor);
    }

    function showImageManager() {
        setImageManger(!imageManger);
    }

    const updateFolder = async (folderId, defaultFolder) => {
        setFolderId(folderId);
        setShowAU(defaultFolder);
    }

    const onChange = (imageList, addUpdateIndex) => {
        fileManager(imageList, addUpdateIndex);
    };

    const fileManager = async (imageList, addUpdateIndex) => {
        if(typeof addUpdateIndex !== 'undefined') {

            setImages(imageList);
            setUploading(true);
            for (const index of addUpdateIndex) {
                const image = imageList[index];
                const file = {};
                file.name = image.file.name;
                file.size = image.file.size;
                file.type = image.file.type;
                file.data_url = image.data_url;

                const response = await uploadImage(folderId, customerId, file);
                if(response.statusCode === 200) {
                    imageList[index] = response.message;

                    setImageIndex(index);
                    setImages(imageList);
                }else {
                    setErrorMessage(response.message);
                }
            }
            setUploading(false);
        }else {
            setShowDeleteModal(true);
            let fileIds = imageList.map(list => list.file_id);
            let image = images.filter(function(image) { return !fileIds.includes(image.file_id) });
            let fileInfo = image && image[0];

            setDeleteImageInfo(fileInfo);
        } 
    };

    async function uploadImage(folderId, customerId, fileList) {
        
        const getFile = await API.graphql({
            query: uploadFile,
            variables: {
                folderId: folderId,
                customerId: customerId,
                fileList: JSON.stringify(fileList)
            }
        })

        return getFile.data.uploadFile ? JSON.parse(getFile.data.uploadFile) : [];
    }

    async function getFileList(cId){
        setLoading(true);
        let customerIds = [6348, cId];
        customerIds = customerIds.join(',');
        console.log('getFileList>>>', customerIds);
        const getFileList = await API.graphql({
            query: getFiles,
            variables: {
                customerIds: customerIds
            }
        })

        let images = getFileList.data.getFiles ? JSON.parse(getFileList.data.getFiles) : [];
        console.log('getFileList>>>', images);
        setImages(images);
        setLoading(false);
    }

    function polutateData(){
        let c_color = JSON.parse(secureLocalStorage.getItem('client_data')).color;
        let c_logo = JSON.parse(secureLocalStorage.getItem('client_data')).logo;

        let one_click = JSON.parse(secureLocalStorage.getItem('client_data')).one_click;
        let edit_res = JSON.parse(secureLocalStorage.getItem('client_data')).pd_team_edit;

        let ece = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_estimate_contact_email;
        let ecfn = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_estimate_contact_first_name;
        let ecln = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_estimate_contact_last_name;
        let ecp = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_estimate_contact_phone;

        let wce = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_work_order_contact_email;
        let wcfn = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_work_order_contact_first_name;
        let wcln = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_work_order_contact_last_name;
        let wcp = JSON.parse(secureLocalStorage.getItem('client_data')).prodocs_work_order_contact_phone;

        console.log('polutateData color>>>', c_color);
        console.log('polutateData logo>>>', c_logo);

        console.log('polutateData one_click>>>', one_click);
        console.log('polutateData edit_res>>>', edit_res);

        console.log('polutateData e email>>>', ece);
        console.log('polutateData e first_name>>>', ecfn);
        console.log('polutateData e last_name>>>', ecln);
        console.log('polutateData e phone>>>', ecp);

        console.log('polutateData w email>>>', wce);
        console.log('polutateData w first_name>>>', wcfn);
        console.log('polutateData w last_name>>>', wcln);
        console.log('polutateData w phone>>>', wcp);

        if(c_color){
            setColor(c_color);
        }
        if(c_logo){
            setLogo(c_logo);
        }

        setOneClick(one_click);
        setEditRestriction(edit_res);
        
        setEstEmail(ece);
        setEstFirstName(ecfn);
        setEstLastName(ecln);
        setEstPhone(ecp);

        setWrkEmail(wce);
        setWrkFirstName(wcfn);
        setWrkLastName(wcln);
        setWrkPhone(wcp);
    }

    async function deleteImage() {
        
        const file = deleteImageInfo;

        deleteImageIndex.push(file.file_id);
      
        setImageIndex(file.file_id);
        setDeleteImageIndex(deleteImageIndex);

        const result = await API.graphql({
            query: deleteFile,
            variables: {
                fileName: file.name,
                customerId: customerId,
                fileId: file.file_id
            }
        });

        const response = result.data.deleteFile ? JSON.parse(result.data.deleteFile) : [];

        if(response.statusCode === 200) {
            let updatedImages = images.filter(function(image) { return !deleteImageIndex.includes(image.file_id) }); 
            
            setImageIndex(file.file_id);
            setImages(updatedImages);

        }else {
            setErrorMessage(response.message);
        }
    }

    async function hanleOneClick(){
        console.log('hanleOneClick oneClick>>>', oneClick);
        let click = !oneClick;
        setOneClick(click);
        console.log('hanleOneClick click>>>', click);

        let val = 0;
        if (click) {
            val = 1;
        }

        let data = JSON.parse(secureLocalStorage.getItem('client_data'));
        data.one_click = val;
        console.log('hanleOneClick data>>>', data);
        secureLocalStorage.setItem('client_data', JSON.stringify(data));

        const result = await API.graphql({
            query: updateQuickGenerate,
            variables: {
                client_id: customerId,
                one_click: val
            }
        });

        console.log('hanleOneClick result>>>', result);
    }

    async function handleEditRestriction(){
        console.log('handleEditRestriction>>>', editRestiction);
        let click = !editRestiction;
        setEditRestriction(click);
        console.log('handleEditRestriction click>>>', click);

        let val = 0;
        if (click) {
            val = 1;
        }

        let data = JSON.parse(secureLocalStorage.getItem('client_data'));
        data.pd_team_edit = val;
        console.log('handleEditRestriction data>>>', data);
        secureLocalStorage.setItem('client_data', JSON.stringify(data));

        const result = await API.graphql({
            query: updateTempEditRestriction,
            variables: {
                client_id: customerId,
                pd_team_edit: val
            }
        });

        console.log('handleEditRestriction result>>>', result);

    }

    function handleEstFirstName(e) {
        console.log('handleEstFirstName', e.target.value);
        setEstFirstName(e.target.value);
    }

    function handleEstLastName(e) {
        console.log('handleEstLastName', e.target.value);
        setEstLastName(e.target.value);
    }

    function handleEstPhone(e) {
        console.log('handleEstPhone', e.target.value);
        setEstPhone(e.target.value);
    }

    function handleEstEmail(e) {
        console.log('handleEstEmail', e.target.value);
        setEstEmail(e.target.value);
    }

    function handleWrkFirstName(e) {
        console.log('handleWrkFirstName', e.target.value);
        setWrkFirstName (e.target.value);
    }

    function handleWrkLastName(e) {
        console.log('handleWrkLastName', e.target.value);
        setWrkLastName(e.target.value);
    }

    function handleWrkPhone(e) {
        console.log('handleWrkPhone', e.target.value);
        setWrkPhone(e.target.value);
    }

    function handleWrkEmail(e) {
        console.log('handleWrkEmail', e.target.value);
        setWrkEmail(e.target.value);
    }

    async function saveEstimateContact (){
        let data = JSON.parse(secureLocalStorage.getItem('client_data'));
        data.prodocs_estimate_contact_email = estEmail;
        data.prodocs_estimate_contact_first_name = estFirstName;
        data.prodocs_estimate_contact_last_name = estLastName;
        data.prodocs_estimate_contact_phone = estPhone;
        console.log('saveEstimateContact data>>>', data);
        secureLocalStorage.setItem('client_data', JSON.stringify(data));
        
        const result = await API.graphql({
            query: updateClientEstContact,
            variables: {
                client_id: customerId,
                prodocs_estimate_contact_email: estEmail,
                prodocs_estimate_contact_first_name: estFirstName,
                prodocs_estimate_contact_last_name: estLastName,
                prodocs_estimate_contact_phone: estPhone
            }
        });

        console.log('saveEstimateContact result>>>', result);
    }

    async function saveWorkOrderContact (){
        let data = JSON.parse(secureLocalStorage.getItem('client_data'));
        data.prodocs_work_order_contact_email = wrkEmail;
        data.prodocs_work_order_contact_first_name = wrkFirstName;
        data.prodocs_work_order_contact_last_name = wrkLastName;
        data.prodocs_work_order_contact_phone = wrkPhone;
        console.log('saveWorkOrderContact data>>>', data);
        secureLocalStorage.setItem('client_data', JSON.stringify(data));
        
        const result = await API.graphql({
            query: updateClientWrkContact,
            variables: {
                client_id: customerId,
                prodocs_work_order_contact_email: wrkEmail,
                prodocs_work_order_contact_first_name: wrkFirstName,
                prodocs_work_order_contact_last_name: wrkLastName,
                prodocs_work_order_contact_phone: wrkPhone
            }
        });

        console.log('saveWorkOrderContact result>>>', result);
    }
    
    useEffect(() => {
        let cId = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        setCustomerId(cId);
        getFileList(cId);
        polutateData();
    }, []);

    return(
        <Wrapper>
            <Menu />
            {
                showDeleteModal &&
                <GeneralPopup 
                    titleText="Are You Sure?"
                    bodyText={<>Are you sure you want to <b>delete</b> this file?</>}
                    showModal={showDeleteModal}
                    setShowModal={setShowDeleteModal}
                    confirm={deleteImage}
                />
            }
            <div className="custom-padding">
                <div className="mt-5">
                    <h4>CUSTOMIZE</h4>
                    <div className="project-info-fields on-always amp-toggle-content is-dormant">
                        <div id="contact-info">
                            <div className="field color d-flex flex-column align-item-center mb-2">
                                <span className="detail_heading_label mb-2">Your color:</span>
                                <input type="color" className="f w-25" value={color} onChange={e => setColorOptions(e.target.value)} />
                            </div>
                            <div className="field logo">
                                <span className="detail_heading_label">Your logo:</span>
                                <div className="mb-3 doc_logo">
                                    <img src={logo} />
                                </div> 

                                <a className="s_nav_item mt-4 d-inline-flex align-items-center" onClick={showImageManager}>
                                    <span className='image-spacing'>{imageManger ? <img src={Check} style={{width:'26px', height:'auto'}} /> : <FaPencilAlt/> }</span> 
                                    {imageManger ? 'Done' : 'Change Logo'}  
                                </a>
                                
                                {imageManger && <div className="upload_img">
                                    {loading && <div className="amp_fm_loading">
                                        <div className="align_middle"></div>
                                        <img className="loader" src={Loader} />			
                                    </div> }
                                    <a  href="javascript:;" className="m-0 text-left uploader_text"  onClick={() => updateFolder(0, false)}>Home </a>
                                    {(!uploading && showAU) && <a  href="javascript:;" className="m-0 text-left uploader_text">&nbsp;/ Scope Technology</a>}       
                                    <div className="align">
                                        {errorMessage && <div class="alert alert-danger" role="alert">
                                            <strong>Error!</strong> { errorMessage }.
                                        </div>}
                                        <ImageUploading
                                            multiple
                                            value={images}
                                            onChange={onChange}
                                            maxNumber={maxNumber}
                                            acceptType={['jpg', 'png']}
                                            dataURLKey="data_url"
                                        >
                                            {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                            }) => (
                                                // write your building 
                                                <div className="upload__image-wrapper">
                                                    {!showAU && <div className="box">
                                                        {uploading && <div className="amp_fm_loading">
                                                            <div className="align_middle"></div>	
                                                        </div> }
                                                        <a href="javascript:;" onClick={() => updateFolder(23, true)}>
                                                            <FaFolder size={100} color={'#414141'}/>
                                                        </a>
                                                        <p className="uploader_text">Scope Technology</p>
                                                    </div>}
                                                    <button
                                                        className="uploader"
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                        >
                                                        <p className="uploader_text"> <b>Click or Drag</b> to add a logo </p>
                                                        <p className="uploader_desc">(.png or.jpg files with a minimum height of 200px)</p>
                                                    </button>
                                                    {Array.isArray(imageList) && imageList.map((image, index) => {
                                                        if(!image.file_id && !image.folder_id) {
                                                            image.folder_id = folderId;
                                                        }
                                                        
                                                        if(image.folder_id == folderId) {
                                                            return(<div key={index} className="box2">
                                                                {image.file_id ?
                                                                    <>
                                                                        {deleteImageIndex.includes(image.file_id) ? <FaCircleNotch size={100} className="awesome_spinner" style={{color:'#414141'}}/> :
                                                                        <>
                                                                            <a style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}} href="javascript:;" onClick={() => setLogoOptions(`${image.url}#file_id=${image.file_id}`)} >
                                                                                <img src={image.file_id ? image.url : image['data_url']} alt="" /> 
                                                                            </a>
                                                                            {image.customer_id === customerId && <button onClick={() => onImageRemove(index)}><FaTrashAlt size={15}/></button>}
                                                                        </>}
                                                                    </> : 
                                                                <FaCircleNotch size={100} className="awesome_spinner" style={{color:'#414141'}}/>}
                                                            </div>)
                                                        }
                                                    })}
                                                </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 border-top">
                    <h4  className="pt-5"> PRODOCS QUICK GENERATE</h4>
                    <p>ProDocs Quick Generate allows you to go straight from a Scope Report in My Scopes to the PDF preview when you click generate.</p>
                    <p style={{fontStyle: 'italic'}}>Note: When Quick Generate is active, you will not see the Property Owner info populate on your estimate. If you want to include Property owner info on each estimate, you may want to leave this feature off.</p>
                    <div style={{width:'90px', height:'auto'}} onClick={hanleOneClick}>
                        <img src={oneClick ? ToggleOn : ToggleOff} />
                    </div>
                </div>
                {
                role == "Admin" || role == "Leader" ?
                <div className="mt-5 border-top">
                    <h4 className="pt-5">PRODOCS TEMPLATES EDITING RESTRICTION</h4>
                    <p>Allow or Restrict Team Members to edit and change ProDocs' Templates.</p>
                    <p style={{fontStyle: 'italic'}}>Note: When 'Allowed', team members will be able to edit their own templates and templates created by other team members, with 'Restricted', they can only edit their own templates.</p>
                    <div style={{width:'90px', height:'auto'}} onClick={handleEditRestriction}>
                        <img src={editRestiction ? ToggleOn : ToggleOff} />
                    </div>
                </div>
                : ""
                }
                <div className="mt-5 border-top">
                    <h4 className="mt-5">DEFAULT CONTACT REPORT DETAILS</h4>
                    <div className="mt-5">
                        <h5>Estimate Contact</h5>
                        <div className="mt-3">
                            <span className="detail_heading_label">First Name:</span>
                            <input type="text" className="setting-input" value={estFirstName} onChange={handleEstFirstName}/>
                        </div>
                        <div className="mt-3">
                            <span className="detail_heading_label">Last Name:</span>
                            <input type="text" className="setting-input" value={estLastName} onChange={handleEstLastName}/>
                        </div>
                        <div className="mt-3">
                            <span className="detail_heading_label">Phone:</span>
                            <input type="text" className="setting-input" value={estPhone} onChange={handleEstPhone}/>
                        </div>
                        <div className="mt-3">
                            <span className="detail_heading_label">Email:</span>
                            <input type="text" className="setting-input" value={estEmail} onChange={handleEstEmail}/>
                        </div>
                        <div className="mt-5">
                            <button
                                className="filter-btn"
                                onClick={saveEstimateContact}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h5>Work Order Contact</h5>
                        <div className="mt-3">
                            <span className="detail_heading_label">First Name:</span>
                            <input type="text" className="setting-input" value={wrkFirstName} onChange={handleWrkFirstName}/>
                        </div>
                        <div className="mt-3">
                            <span className="detail_heading_label">Last Name:</span>
                            <input type="text" className="setting-input" value={wrkLastName} onChange={handleWrkLastName}/>
                        </div>
                        <div className="mt-3">
                            <span className="detail_heading_label">Phone:</span>
                            <input type="text" className="setting-input" value={wrkPhone} onChange={handleWrkPhone}/>
                        </div>
                        <div className="mt-3">
                            <span className="detail_heading_label">Email:</span>
                            <input type="text" className="setting-input" value={wrkEmail} onChange={handleWrkEmail}/>
                        </div>
                        <div className="mt-5">
                            <button
                                className="filter-btn"
                                onClick={saveWorkOrderContact}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  min-height: 95vh;
  width: 100%;
  background: #f7f9fa;
  position: relative;

  .custom-padding-left-doc {
    padding-left: 10px !important;
  }

  .custom-padding {
    padding: 0 121px 130px 121px;

    @media (max-width: 1024px) {
      padding: 0 50px 100px 50px;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .upload_img{
    width:100%;
    min-height:300px;
    border:1px solid #B4BEBF;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    position: relative;
    flex-wrap:wrap;
    padding:10px;
    margin:10px 0;

    @media(max-width:600px){
        min-height:460px;
    }

    .amp_fm_loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: rgba(255,255,255,0.8);
        text-align: center;
    }

    .align_middle {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0;
        margin-right: -.3em !important;
    }

   .align{
       width:100%;
       display:flex;
       align-items:center;
       justify-content:center;
       flex-wrap:wrap;
    
       .upload__image-wrapper{
           width:100%;
           display:flex;
           align-items:center;
           justify-content:center;
           flex-wrap:wrap;
           margin-bottom: 6%;
           overflow-y: scroll;
           height: 370px;
       }

       .uploader{
        position: absolute;
        bottom:0;
        background:transparent;
        text-align:center;
        border:none;
        outline:none;
        width:100%;
       }
   }

    .box{
        height:200px;
        width:200px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        border:1px solid #bdbdbd;
        border-radius:5px;
        margin:20px;
        position: relative;

        &:hover{
        background:rgba(0,0,0,0.1);
    }
    }

    .box2{
        height:200px;
        width:200px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        border:1px solid #bdbdbd;
        border-radius:5px;
        margin:10px;
        padding:10px;
        position: relative;
        &:hover{
        background:rgba(0,0,0,0.1);
         }

     img{
         width:150px;
         height:auto;
     }    
    button{
            position: absolute;
            bottom:1px;
            right:1px;
            border:none;
            outline:none;
            background:transparent;
        }
    }
  }
  
    .uploader_text{
        color: #212E33;
        font-family: Rajdhani;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 0;
    }
    .uploader_desc{
        color: #212E33;
        font-family: Rajdhani;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 8px;
    }

    .detail_heading_label{
        color: #212E33;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    .image-spacing {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    .w-25{
        width: 25% !important;

        @media(max-width:600px){
            width: 50% !important;
        }
    }
    
    .border-top{
        border-top: 1px solid #bdbdbd;
    }
    .border-bottom{
        border-bottom: 1px solid #bdbdbd;
    }
    
    .cursor-pointer{
        cursor: pointer;

        &:hover  {
            color: #F7941C !important;
          }
    }

    .setting-input {
        height: 40px;
        width: 100%;
        outline: none;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #B4BEBF;
        color: #212E33;
        font-style: normal;
        font-size: 14px;
        margin-top: 8px;
    }
    .filter-btn {
        width: 135px;
        height: 44px;
        border-radius: 4px;
        padding: 7px;
        background-color: #f7941c;
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        color: #212e33;
        font-family: Rajdhani;
        border: none;
        outline: none;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          color: #fff;
          background-color: #000000;
        }
    }
  `;
